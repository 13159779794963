import { defineStore } from "pinia";

export const useNavbarInfo = defineStore("navbarInfo", () => {
  const navbar = ref<{
    title: string;
    tabs: { title: string; link: string }[];
  }>({
    title: "Beesphone",
    tabs: [],
  });

  const changeInfo = (
    title: string,
    tabs: { title: string; link: string }[]
  ) => {
    navbar.value.title = title;
  };

  return {
    navbar,
    changeInfo,
  };
});
