import { gql } from "graphql-request";

export type User = {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  country: string;
  dob: Date;
  timezone: string;
};

export type UserFeedBack = {
  UserId: string;
  Feedback: string;
  files?: Array<File>;
  ContactMe: boolean;
};

const gqlRequest = async (query: string, variables = {}) => {
  const { $gqlRequest } = useNuxtApp();
  return await ($gqlRequest as any)(query, variables);
};

export const getUserDetails = async (userId: string) => {
  const query = gql`
    query Me {
      user {
        userId
        cart {
          meta {
            bpNumberPrices {
              currency
              prices {
                r_interval
                r_amount
                incl_mins
              }
            }
          }
          amount {
            subTotal
            total
            tax
            fees
          }
          createdAt
          currency
          lastModifiedAt
          paymentProvider
          status
          items {
            category
            did
            quantity
            recurring
            reservationExpires
            price {
              amount
            }
          }
        }
        firstName
        lastName
        mobileNumber
        timezone
        dob
        country
        tos {
          accepted
          acceptedAt
          version
        }
      }
    }
  `;

  const variables = {
    userId: userId,
  };

  const data = await gqlRequest(query, variables);
  return data;
};

export const updateUserProfile = async (userId: string, userDetails: User) => {
  const query = gql`
    mutation updateUserNames($user: UserUpdateInput!, $userId: String) {
      updateOneUser(query: { userId: $userId }, set: $user) {
        firstName
        lastName
        mobileNumber
        dob
        timezone
        country
      }
    }
  `;

  const variables = {
    userId: userId,
    user: userDetails,
  };

  const data = await gqlRequest(query, variables);
  return data;
};
