// prevent user to be able to access the route without being logged in
export default defineNuxtRouteMiddleware((to) => {
  if (import.meta.client) {
    // Perform client-side specific actions here
    const isAuthenticatePage = to.path.startsWith("/auth");
    const accessToken = useCookie("access_token");
    const mayBeAuthenticated = !!localStorage.getItem("userId") && !!accessToken.value;

    if (!mayBeAuthenticated && !isAuthenticatePage) {
      // remove the access token and userId
      useUserStore().changeUser({});
      localStorage.removeItem("userId");
      localStorage.removeItem("refresh_token");
      accessToken.value = "";
      const redirectTo = to.fullPath;
      return navigateTo("/auth/signin?redirectTo=" + redirectTo);
    }

    if (mayBeAuthenticated && isAuthenticatePage) {
      if (to.path !== "/") {
        return navigateTo("/");
      }
    }
  }
});
