import VueTelInput from "vue-tel-input";
import "vue-tel-input/vue-tel-input.css";

const globalOptions = {
  mode: "auto",
  invalidMsg: "Not valid",
  inputOptions: {
    styleClasses:
      "w-full py-3.5 px-4 font-manrope font-semibold text-sm !rounded-xl text-neutral-600 bg-blue-50 placeholder:font-manrope placeholder:text-sm",
    required: true,
  },
  styleClasses:
    "!rounded-xl !border-[#e5e7eb] bg-blue-50 focus-within:!border-primary !shadow-sm transition-all",
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(VueTelInput, globalOptions);
});
