import validate from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import navbar_45global from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/middleware/navbar.global.ts";
import route_45global from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/middleware/route.global.ts";
import manifest_45route_45rule from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  navbar_45global,
  route_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}