import { default as account_45settingxsTkDrp9COMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/account-setting.vue?macro=true";
import { default as forgot_45passwordng4N9IKXXVMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/forgot-password.vue?macro=true";
import { default as logoutufYUbI9l7lMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/logout.vue?macro=true";
import { default as _91token_93qmcmzEWb4rMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/reset/[token].vue?macro=true";
import { default as signin6M3n1YWXX3Meta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/signin.vue?macro=true";
import { default as signupXaZq103TKlMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/signup.vue?macro=true";
import { default as billingAN6JqjmFAcMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/billing.vue?macro=true";
import { default as callsPMsqDXa80IMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/calls.vue?macro=true";
import { default as contactR8NxWY8Cn3Meta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/contact.vue?macro=true";
import { default as dashboardYlVUyAVbvyMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/dashboard.vue?macro=true";
import { default as indexmTXYmJ1hwoMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/index.vue?macro=true";
import { default as messagesOaERNJXNVuMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/messages.vue?macro=true";
import { default as notificationsUuyl9pNdEoMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/notifications.vue?macro=true";
import { default as _91numberId_93Ukb2efVCqxMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/number-registrations/[numberId].vue?macro=true";
import { default as indexS2ZmxIrOwwMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/number-registrations/index.vue?macro=true";
import { default as newic5ERpvwndMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/number-registrations/new.vue?macro=true";
import { default as indexWsiaKKTXhlMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/numbers/index.vue?macro=true";
import { default as select_45numberiDB0CTT50VMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/select-number.vue?macro=true";
import { default as supportX5OQTkYF7EMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/support.vue?macro=true";
import { default as teamsE6hEWjVEIBMeta } from "/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/teams.vue?macro=true";
export default [
  {
    name: "account-setting",
    path: "/account-setting",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/account-setting.vue")
  },
  {
    name: "auth-forgot-password",
    path: "/auth/forgot-password",
    meta: forgot_45passwordng4N9IKXXVMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-logout",
    path: "/auth/logout",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/logout.vue")
  },
  {
    name: "auth-reset-token",
    path: "/auth/reset/:token()",
    meta: _91token_93qmcmzEWb4rMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/reset/[token].vue")
  },
  {
    name: "auth-signin",
    path: "/auth/signin",
    meta: signin6M3n1YWXX3Meta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/signin.vue")
  },
  {
    name: "auth-signup",
    path: "/auth/signup",
    meta: signupXaZq103TKlMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/auth/signup.vue")
  },
  {
    name: "billing",
    path: "/billing",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/billing.vue")
  },
  {
    name: "calls",
    path: "/calls",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/calls.vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/contact.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/dashboard.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexmTXYmJ1hwoMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/index.vue")
  },
  {
    name: "messages",
    path: "/messages",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/messages.vue")
  },
  {
    name: "notifications",
    path: "/notifications",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/notifications.vue")
  },
  {
    name: "number-registrations-numberId",
    path: "/number-registrations/:numberId()",
    meta: _91numberId_93Ukb2efVCqxMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/number-registrations/[numberId].vue")
  },
  {
    name: "number-registrations",
    path: "/number-registrations",
    meta: indexS2ZmxIrOwwMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/number-registrations/index.vue")
  },
  {
    name: "number-registrations-new",
    path: "/number-registrations/new",
    meta: newic5ERpvwndMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/number-registrations/new.vue")
  },
  {
    name: "numbers",
    path: "/numbers",
    meta: indexWsiaKKTXhlMeta || {},
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/numbers/index.vue")
  },
  {
    name: "select-number",
    path: "/select-number",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/select-number.vue")
  },
  {
    name: "support",
    path: "/support",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/support.vue")
  },
  {
    name: "teams",
    path: "/teams",
    component: () => import("/home/runner/work/beesphone-web-app/beesphone-web-app/beesphone-web/pages/teams.vue")
  }
]