import { GraphQLClient, ClientError } from "graphql-request";
import type { Auth } from "firebase/auth";

export interface AtlasAuthRefreshTokenResponse {
  access_token: string;
}

export default defineNuxtPlugin((nuxtApp) => {
  const runtimeConfig = useRuntimeConfig();
  const endpoint = runtimeConfig.public.endpoint;

  /**
   * Refresh the access token
   *
   * @returns {string} The new access token
   * @throws {Error} If the refresh token is invalid
   */
  const refreshAccessToken = async (): Promise<string> => {
    const runtimeConfig = useRuntimeConfig();
    const graphqlRefreshTokenUrl = runtimeConfig.public
      .graphqlRefreshTokenUrl as string;
    const cookies = useCookie("access_token");
    const refresh_token = localStorage.getItem("refresh_token");

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${refresh_token}`);

    const data: AtlasAuthRefreshTokenResponse = await $fetch(
      graphqlRefreshTokenUrl,
      {
        method: "POST",
        headers: myHeaders,
        body: "",
        redirect: "follow",
      }
    );

    const newAccessToken = data.access_token;
    cookies.value = newAccessToken;

    return newAccessToken;
  };

  /**
   * Make a GraphQL request
   *
   * @param {string} query The query to make
   * @param {object} variables The variables to pass to the query
   * @returns {Promise<object>} The response from the query
   */
  const gqlRequest = async (query: string, variables: object = {}): Promise<object> => {
    const cookies = useCookie("access_token");
    const token = cookies.value;
    let client = new GraphQLClient(endpoint, {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    try {
      // Attempt the request
      return await client.request(query, variables);
    } catch (error) {
      if (error instanceof ClientError && error.response.status === 401) {
        // If 401 Unauthorized, refresh the token
        const newToken = await refreshAccessToken();

        // Retry the request with the new token
        client = new GraphQLClient(endpoint, {
          headers: {
            authorization: `Bearer ${newToken}`,
          },
        });

        return await client.request(query, variables);
      } else {
        throw error;
      }
    }
  };

  /**
   * Make an authenticated fetch request
   * @param {string} url The URL to fetch
   * @param {string} method The method to use
   * @param {object} body The body to send
   */
  const authdFetch = async (
    url: string,
    method: string = "GET" as const,
    body?: object,
    hasFiles = false
  ) => {
    const cookie = useCookie("id_token");
    const token = cookie.value;
    const myHeaders = new Headers();
    hasFiles || myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${token}`);

    const bodyData = !hasFiles ? JSON.stringify(body) : body;

    const options = {
      method,
      headers: myHeaders,
      ...(body && { body: bodyData }),
    };

    console.log(options, "token");

    try {
      return await $fetch(url, options);
    } catch (error) {
      if (
        error instanceof Error &&
        error.name === "FetchError" &&
        (error as any).response?.status === 400
      ) {
        const nuxtApp = useNuxtApp();
        const auth = nuxtApp.$auth as Auth;

        const newToken = await auth.currentUser?.getIdToken(true);
        if (!newToken) {
          throw error;
        }
        const authStore = useAuthStore();
        cookie.value = newToken;
        authStore.setIdToken(newToken);

        // Retry the request with the new token
        myHeaders.set("Authorization", `Bearer ${newToken}`);
        return await $fetch(url, options);
      }
      throw error;
    }
  };

  nuxtApp.provide("gqlRequest", gqlRequest);
  nuxtApp.provide("authdFetch", authdFetch);
});