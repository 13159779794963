export interface User {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  email: string;
  country: string;
  dob: string;
  timezone: string;
  userId: string;
  cart: any;
  orders: any;
}

export const useUserStore = defineStore("userStore", () => {
  const userDetails = ref<Partial<User>>({});

  const changeUser = (user: Partial<User>) => {
    userDetails.value = user;
  };

  return {
    userDetails,
    changeUser,
  };
});
