import { onAuthStateChanged } from "firebase/auth";
import type { Auth } from "firebase/auth";
import { getUserDetails } from "~/services/user";

export default defineNuxtPlugin(() => {
  const userStore = useUserStore();
  const { userDetails } = storeToRefs(userStore);
  const authStore = useAuthStore();

  const nuxtApp = useNuxtApp();
  const auth = nuxtApp.$auth as Auth;

  onAuthStateChanged(auth, async (user) => {
    const accessToken = useCookie("access_token", {
      secure: process.env.NODE_ENV === "production"
    });
    const idToken = useCookie("id_token", {
      secure: process.env.NODE_ENV === "production"
    });
    if (user) {
      const token: string = await user.getIdToken();
      // To prevent the error when user is not already logged in and there is no access token
      // the onAuthStateChanged will be executed immediately after signInWithEmailAndPassword is executed
      // And there will no access token for graphql api's
      
      // Store the ID token in a secure, HTTP-only cookie
      idToken.value = token;
      // set id token in auth store
      authStore.setIdToken(token);
      
      if (accessToken.value) {
        let response = null;
        try {
          response = await getUserDetails(token);
        } catch (error) {
          response = null;
        }

        if (response) {
          userStore.changeUser({
            ...response.user,
            email: user.email,
          } as unknown as User);

          localStorage.setItem("userId", response.user.userId);
        }
      }

      // // hide splash screen
      useSplashScreenStore().hideSplashScreen();
    } else {
      userDetails.value = {};
      accessToken.value = "";
      idToken.value = "";
      authStore.clearIdToken();
      // hide splash screen
      useSplashScreenStore().hideSplashScreen();
    }
  });
});
