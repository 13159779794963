export const useAuthStore = defineStore('authStore', () => {
  const idToken = ref<string>('');
  
  const setIdToken = ( token: string) => {
    idToken.value = token;
  };

  const clearIdToken = () => {
    idToken.value = '';
  }

  return {
    idToken,
    setIdToken,
    clearIdToken
  };
});